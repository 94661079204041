import React, { MouseEvent } from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Category } from "../common/category.model"

type CategoryCardProps = {
  category: Category
  count: number
  onCategoryClick: (category: Category) => void
}

const CategoryCard = (props: CategoryCardProps) => {
  const image = getImage(props.category.image)
  if (!image) {
    return <></>
  }
  const handleClick = (e: MouseEvent<HTMLElement>) => {
    props.onCategoryClick(props.category)
  }
  return (
    <div onClick={handleClick} className="w-60 h-24 lg:w-72 cursor-pointer shadow-md hover:shadow-lg active:shadow-sm">
      <div className="h-full flex flex-row">
        <GatsbyImage
          className="min-w-24 min-h-24 max-w-24 max-h-24 w-24 h-24 flex-shrink-0 bg-gray-100"
          imgClassName="w-full h-full"
          draggable="false"
          image={image}
          alt={props.category.name}
        />
        <div className="p-2">
          <div className="text-gray-700 text-sm font-semibold">
            {props.category.name} <span className="italic text-xs">({props.count})</span>
          </div>
          <div className="max-lines-3 text-gray-400 text-xs mt-1">{props.category.shortDescription}</div>
        </div>
      </div>
    </div>
  )
}

export default CategoryCard
