import React, { useCallback, useEffect, useRef, useState } from "react"
import { graphql, navigate, useStaticQuery } from "gatsby"

import Head from "../components/header/head"
import Layout from "../components/layout"
import {
  HEADER_IMAGE_RECHERCHE,
  HEADER_PAGE_NAME_RECHERCHE,
  HEADER_TITLE_RECHERCHE,
  HEADER_SUB_TITLE_RECHERCHE,
} from "../utils/constants"
import {
  getSearchResults,
  hasFilters,
  removeDuplicates,
  isBrowser,
  getSearchUrl,
  getSearchQueryFromUrl,
  encodeURL,
} from "../utils/utils"
import SearchResult from "../components/search/search-result"
import type { Post } from "../common/post.model"
import type { SearchQueryOptions } from "../components/search/search-query-options.model"
import CategoryCard from "../components/category-card"
import { Category } from "../common/category.model"

const RecherchePage = ({ location }) => {
  const postsData = useStaticQuery(graphql`
    query {
      allContentfulPost(sort: { fields: publishedDate, order: DESC }) {
        edges {
          node {
            title
            slug
            city
            publishedDate(formatString: "DD/MM/YYYY", locale: "fr")
            image {
              gatsbyImageData(height: 576, placeholder: BLURRED)
            }
            featured
            tags
            originalAuthor {
              name
            }
            category {
              name
              shortDescription
              image {
                gatsbyImageData(height: 576, placeholder: BLURRED)
              }
            }
            postType {
              typeId
            }
          }
        }
      }
    }
  `)
  // reorganize query data
  const posts: Post[] = postsData.allContentfulPost.edges
    .map((ad: any) => ad.node)
    .filter((post: Post) => post.postType?.typeId !== "SITE_MISC")
  // extract categories and authors lists
  const categories: { category: Category; count: number }[] = posts
    .reduce((acc, post) => {
      if (post.category) {
        const existingCat = acc.find(c => post.category && c.category.name === post.category.name)
        if (!existingCat) {
          acc.push({ category: post.category, count: 1 })
        } else {
          existingCat.count++
        }
      }
      return acc
    }, [] as { category: Category; count: number }[])
    .filter(c => c.category.name !== "à propos")
    .sort((c1, c2) => {
      const titleA = c1.category.name.toLocaleLowerCase()
      const titleB = c2.category.name.toLocaleLowerCase()
      if (titleA < titleB) {
        return -1
      } else if (titleA > titleB) {
        return 1
      } else {
        return 0
      }
    })
  const categoriesNames = categories.map(c => c.category.name)
  const originalAuthorsNames = removeDuplicates(posts.map(p => !!p.originalAuthor && p.originalAuthor.name))
  // state
  const [filteredResults, setFilteredResults] = useState<Post[]>([])
  const [isCurrentlySearching, setIsCurrentlySearching] = useState<boolean>(false)

  const pageTopRef: any = useRef(null)

  useEffect(() => {
    const query = getSearchQueryFromUrl(location.search)
    if (!query || (!query.all && !query.searchQuery)) {
      setFilteredResults([])
      setIsCurrentlySearching(false)
    } else if (query.searchQuery) {
      setFilteredResults(getSearchResults(posts, query.searchQuery))
      setIsCurrentlySearching(true)
    }
  }, [location])

  const onSearch = (searchQuery: SearchQueryOptions) => {
    if (hasFilters(searchQuery)) {
      navigate(encodeURL(getSearchUrl(searchQuery)))
    } else {
      navigate("/recherche")
    }
  }
  const onCategorySelect = (category: Category) => {
    navigate(encodeURL(`?category=${category.name}`))
    scrollToTop()
  }
  const onClearSearch = () => {
    navigate("/recherche")
  }
  const scrollToTop = () => {
    if (!!pageTopRef && isBrowser) {
      // document.body.scrollTo({ top: pageTopRef.current.offsetTop - 80, behavior: "smooth" })
      window.scrollTo(0, pageTopRef.current.offsetTop - 80)
      // pageTopRef.current.scrollIntoView({ behavior: "smooth" })
    }
  }
  const headerSearchOptions = {
    categories: categoriesNames,
    originalAuthorsNames: originalAuthorsNames,
    authorsTitle: "PAR AUTEURS",
    postTypes: true,
    onSearch: onSearch,
  }
  return (
    <Layout
      headerImg={HEADER_IMAGE_RECHERCHE}
      headerSearch={headerSearchOptions}
      headerPageName={HEADER_PAGE_NAME_RECHERCHE}
      headerTitle={HEADER_TITLE_RECHERCHE}
      headerSubTitle={HEADER_SUB_TITLE_RECHERCHE}
      headerColor=""
      focusSearch={true}
    >
      <Head title="Recherche" description="La Voie des Maîtres: recherche globale" />
      <div className="py-8" ref={pageTopRef}>
        {!isCurrentlySearching ? (
          <div className="grid sm:grid-cols-2 xl:grid-cols-3 gap-y-12 justify-items-center">
            {categories.map(categoryData => {
              return (
                <div key={categoryData.category.name}>
                  <CategoryCard
                    category={categoryData.category}
                    count={categoryData.count}
                    onCategoryClick={onCategorySelect}
                  ></CategoryCard>
                </div>
              )
            })}
          </div>
        ) : (
          <SearchResult
            posts={filteredResults}
            onClearSearch={onClearSearch}
            resultsTitle="Résultats trouvés :"
          ></SearchResult>
        )}
      </div>
    </Layout>
  )
}
export default RecherchePage
